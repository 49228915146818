.template-designer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: var(--body_container_width);
}

.template-input-range {
	margin: 5px 5px !important;
}

.template-enter-conditions, .template-exit-conditions, .template-signal-conditions {
	display: flex;
	flex-direction: row;
}

.template-grid-tile {
	margin: 10px !important;
}

.template-input-field {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 10px 10px 10px;
	height: 100%;
}
