.navbar {
	min-height: var(--navbar_height) !important;
	height: var(--navbar_height) !important;
	padding: 0px !important;

/* 	 Use this to make the navbar scroll with you. */
/*	position: fixed !important;
	top: 0;
	width: 100%;
	z-index: 1000;*/
}

.navbar-list {
	display: flex;
	justify-content : flex-end;
	align-items: center;
	width: 100%;
	height: 100% !important;
	margin: 0px !important;
	padding: 0px !important;
}
.navbar-list a {
	text-decoration: none;
}
.navbar-list a:hover {
	cursor: pointer;
}
.navbar-list-item {
	display: flex;
	justify-content: center;
	align-items: center;
}
.navbar-list-item span {
	font-size: var(--navbar_fontsize) !important;
}

.navbar-list .box {
	width: 100%;
	height: 100% !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.navbar-list a {
	height: 100% !important;
}


@media only screen and (min-height: 400px) {
	.navbar-separator {
		line-height: 1.75 !important;
	}
}

@media only screen and (min-height: 600px) {
	.navbar-separator {
		line-height: 1.75 !important;
	}
}

@media only screen and (min-height: 800px) {
	.navbar-separator {
		line-height: 2.0 !important;
	}
}

@media only screen and (min-height: 1000px) {
	.navbar-separator {
		line-height: 2.5 !important;
	}
}

@media only screen and (min-height: 1200px) {
	.navbar-separator {
		line-height: 2.75 !important;
	}
}
