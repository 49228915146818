body {
	padding: 0px;
	margin: 0px;
}

.simulation-header-label .MuiFormControlLabel-label {
	color: var(--font_main_color);
}
.position-header-label .MuiFormControlLabel-label {
	color: var(--font_main_color);
}

.center-overlay-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	padding: 10px;
}

.strategy-builder, .simulation-table {
	width: 100%;
}

.flex-row-start {
	display: flex !important;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.flex-row-space-around {
	display: flex !important;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}
.flex-row-space-between {
	display: flex !important;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.flex-row-center {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.flex-col-center {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center; 
}
.flex-col-space-between {
	display: flex !important;
	flex-direction: column;
	justify-content: space-between;
	align-items: center; 
}
.flex-col-space-around {
	display: flex !important;
	flex-direction: column;
	justify-content: space-around;
	align-items: center; 
}

.about-cont {
	padding: 20px;
	margin: 30px 0px 30px 0px;
}
.about-p {
	text-indent: 2.5em;
	margin: 20px !important;
}
.signin-box {
	width: 100%;
}
.login-widget, .signup-widget {
	padding: 30px !important;
	 width: fit-content !important;
	max-width: 40% !important;
}
.signup-widget {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.signup-tf, .login-tf {
	margin: 15px 0px 5px 0px !important;
}

.signup-input::placeholder {
	font-size: 1.0em;
}
.signin-toggle-text {
	width: fit-content;
	margin-top: 10px !important;
}
.signin-toggle-text:hover {
	cursor: pointer;
}
.strategy-list {
	display: flex;
	border-right: 1px solid gray;
	height: var(--body_height);
	overflow-y: scroll; /* This adds the scroll bar to the material ui list*/
}
.strategy-list a {
	text-decoration: none;
	color: black;
}


.strategy-dashboard {
	display: flex;
	max-height: var(--body_height);
	overflow-y: hidden;
}

.strategy-dashboard-body {
	width: 100%;
	max-height: var(--body_height);
	overflow-y: scroll;
}


.simulation-parameters .continuous-stats {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin: 10px auto 10px auto;
	padding: 3px;
}

.simulation-parameters .continuous-stats .continuous-stats-refresh:hover {
	cursor: pointer;
}

.security-autocomplete .MuiOutlinedInput-notchedOutline, .simulation-time-selector .MuiOutlinedInput-notchedOutline {
	border: 1px solid var(--primary_color);
	border-radius: 4px;
}

.strategy-list-right-border {
	width: 5px;
	cursor: pointer
}
.strategy-list-right-border:hover {
	cursor: ew-resize;
}

.position-row:hover {
	background-color: rgba(0, 170, 237, 0.08); /* This was copied from the color of the hover of the Material UI StrategyList */
	color: white;
	cursor: pointer
}

.position-row-selected {
	background-color: rgba(0, 170, 237, 0.08); /* This was copied from the color of the hover of the Material UI StrategyList */
	color: white;
}

.payment-method-table thead, .payment-method-table tbody {
	width: 100% !important;
	display: flex;
}

.payment-method-table tr {
	width: 100% !important;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.payment-method-table tr td, .payment-method-table tr th {
	padding: 5px !important;
	border: none;
	width: 25%;
	text-align: center;
}

.dashboard-box {
	width: var(--body_container_width);
	margin: auto;
}

.group-rename-box {
	width: var(--body_container_width);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-top: 40px;
}
.strategy-rename-box {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.simulation-parameters {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin: 40px 0px 40px 0px;
}
.group-view-cont {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.group-view-divider {
	width: var(--body_container_width);
}
.support-box, .pricing-box, .profile-box, .about-box {
	width: var(--body_container_width);
	margin: auto;
}

.pricing-box {
	height: 70vh
}

@media only screen and (min-width: 900px) {
	:root {
		--body_container_width: 80vw;
	}
	.dashboard-box {
		margin: 40px auto 40px auto;
	}
	.signin-box {
		margin-top: 50px;
	}
	.strategy-list {
		min-width: 22vw;
	}
}
@media only screen and (min-width: 1200px) {
	:root {
		--body_container_width: 75vw;
	}
	.dashboard-box {
		margin: 40px auto 40px auto;
	}
	.signin-box {
		margin-top: 50px;
	}
	.strategy-list {
		min-width: 23vw;
	}
}
@media only screen and (min-width: 1400px) {
	.strategy-list {
		min-width: 20vw;
	}
}
@media only screen and (min-width: 1500px) {
	.signin-box {
		margin-top: 100px;
	}
}

@media only screen and (min-width: 1800px) {
	:root {
		--body_container_width: 70vw;
	}
	.dashboard-box {
		margin: 40px auto 40px auto;
	}
	.signin-box {
		margin-top: 150px;
	}
	.strategy-list {
		min-width: 16vw;
	}
}

@media only screen and (min-width: 2100px) {
	:root {
		--body_container_width: 60vw !important;
	}
	.signin-box {
		margin-top: 150px;
	}
}
