.landing-page {
	text-align: center;
}


.banner, .banner img{
	width: 100%;
}

.banner {
	background-color: #c0c0c0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	box-shadow: 0px 0px 5px grey;
}


.banner-img-next {
	position: absolute;
	left: 0px;
	z-index: 12;
	padding: 10px 10px 10px 10px;
	border-radius: 0px 5px 5px 0px;
}
.banner-img-next-btn {
	width: fit-content;
	margin: 0px;
	padding: 0px !important;
}
.banner-img-next svg {
	opacity: 1.0;

}
.banner-img-next:hover {
	cursor: pointer;
	opacity: 1.0;
}

.banner img {
	opacity: 0.3;
	position: absolute;
	z-index: 1;
}

.banner img:hover {
	opacity: 0.7;
}

.banner-focus-cont, .banner-focus-cont-retracted {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	z-index: 2; /* TODO: Change this to be controlled by Material UI ?*/
	padding: 10px 10px 10px 10px;
	border-radius: 5px;
	border-width: 1px;
	border-style: solid;
}
.banner-focus-cont-retracted {
	width: fit-content;
	height: fit-content;
/* 	padding: 0px; */
	position: absolute;
	right: 0px;
}

.banner-focus-cont .retract-focus, .banner-focus-cont-retracted .retract-focus {
	color: white;
	width: fit-content;
	margin: 0px;
	padding: 0px;
}

.banner-btn-cont {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 30px;
}

.banner-focus-cont h1, .banner-focus-cont h6 {
	text-align: center;
	width: 80%;
	margin: 0px auto 0px auto;
}

.banner-focus-cont button {
	display: flex;
}

.landing-stepper-container {
	width: 80% !important;
	max-width: 100vw !important;
	display: flex !important;
	background-color: white;
	box-shadow: 0px 0px 5px grey;
	margin: 25px 25px 25px 25px;
}

.landing-stepper-container-demo-box {
	width: fit-content;
	margin: 30px auto 60px auto;
}
.landing-stepper-container-demo-box:hover {
	cursor: pointer;
}
.landing-stepper-container-demo-icon {
	font-size: 128px !important;
	margin-top: 20px;
}


.landing-stepper {
	display: flex;
	width: 100%;
	padding: 50px 25px 50px 25px;
}

/* For a rotating words animation. */
/* .rw-words {
	display: inline;
}
.rw-words span {
	animation: rotateForward 8s linear infinite 0s;
	position: absolute;
	overflow: hidden;
	opacity: 0;
}
.rw-words span:nth-child(2) {
	animation-delay: 2s;
}
.rw-words span:nth-child(3) {
	animation-delay: 4s;
}
.rw-words span:nth-child(4) {
	animation-delay: 6s;
} */

@media only screen and (min-width: 900px) {
	.banner {
		height: 600px;
	}
	.banner-img {
		left: 0;
		width: fit-content;
		height: inherit;
	}
	.banner-focus-cont {
		width: 60%;
		margin-left: 50%;
		height: 90%;
	}
}
@media only screen and (min-width: 1200px) {
	.banner {
		height: 600px;
	}
	.banner-img {
		left: 0;
		width: fit-content;
		height: inherit;
	}
	.banner-focus-cont {
		width: 50%;
		margin-left: 60%;
		height: 90%;
	}
}
@media only screen and (min-width: 1500px) {
	.banner {
		height: 750px;
	}
	.banner-img {
		left: 0;
		width: fit-content;
		height: inherit;
	}
	.banner-focus-cont {
		width: 50%;
		margin-left: 60%;
		height: 80%;
	}
}

@media only screen and (min-width: 1800px) {
	.banner {
		height: 850px;
	}
	.banner-img {
		left: 0;
		width: fit-content;
		height: inherit;
	}
	.banner-focus-cont {
		width: 45%;
		margin-left: 65%;
		height: 70%;
	}
}

@media only screen and (min-width: 2100px) {
	.banner {
		height: 800px;
	}
	.banner-img {
		left: 0;
		width: fit-content;
		height: inherit;
	}
	.banner-focus-cont {
		width: 40%;
		margin-left: 70%;
		height: 70%;
	}
}
