
.strategy-view {
	width: var(--body_container_width) !important;
	margin: 0px auto 0px auto;
}

.strategy-details, .position-table, .metrics-grid, .assemble-charts {
	width: 100%;
	margin: 40px 0px 40px 0px;
}

.chart-container {
	margin: 10px 0px 10px 0px;
	padding: 20px 0px 20px 0px;
	width: 100%;
	overflow-x: scroll;
}

.position-header-cell:hover {
	cursor: pointer;
}

.metric-grid-tile {
	/*width: 10vw;
	height: 10vw;

	max-width: 10vw !important;
	max-height: 10vw;*/

	padding: 10px;
	margin: 5px !important;
	display: flex;
	flex-direction: column !important;
	justify-content: flex-start !important;
}

.metric-tile-value {
	text-align: center;
}
.metric-grid-tile:hover {
/* 		cursor: pointer; */
}

.metric-option-tile {
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: center;
	margin: 5px !important;
}
.metric-option-tile:hover {
	cursor: pointer;
}

@media only screen and (min-width: 900px) {
	.metric-tile-value {
		margin: 10px 0px 10px 0px !important;
	}
	.simulation-details-text {
		width: 60%;
	}
}
@media only screen and (min-width: 1200px) {
	.metric-tile-value {
		margin: 15px 0px 15px 0px !important;
	}
	.simulation-details-text {
		width: 50%;
	}
}
@media only screen and (min-width: 1500px) {
	.metric-tile-value {
		margin: 20px 0px 20px 0px !important;
	}
	.simulation-details-text {
		width: 40%;
	}
}
