.profile-box {
	width: var(--body_container_width);
	margin: auto;
}
.profile-section-cont {
	width: 100%;
	margin: 30px 0px 30px 0px;
}

.update-toggle:hover {
	cursor: pointer;
}


.section-div {
	margin: 10px 0px 30px 0px !important;
}

.profile-section {
	display: flex !important;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	max-width: 100% !important;
}

.phone-input:placeholder {
	font-size: 1.0em;
}

.profile-text-split {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.demo-alert, .unverified-alert {
	margin: 20px 0px 20px 0px;
	padding: 5px 15px 5px 5px !important;
}

.pro-benefits-grid {
	justify-content: center;
}

.pro-benefits-tile {
	margin: 10px !important;
	text-align: center;
	padding: 20px !important;
}

.upgrade-box .upgrade-text {
	 widht: 80%;
	 text-align: center;
	 margin: 20px 0px 20px 0px !important;
}
.upgrade-box .upgrade-btn {
	margin: 40px 0px 40px 0px;
	padding: 15px 40px 15px 40px !important;
}

.reset-password-close-icon:hover, .verification-input-toggle-text:hover {
	cursor: pointer;
}

.manage-membership-tile {
	margin: 20px 0px 20px 0px;
	padding: 10px 20px 10px 20px;
	border-width: 1px;
	border-style: solid;
}

@media only screen and (min-width: 900px) {
	.section-div {
		width: 80%;
	}
	.profile-text-split {
		width: 70%;
	}
}
@media only screen and (min-width: 1200px) {
	.section-div {
		width: 80%;
	}
	.profile-text-split {
		width: 65%;
	}
}
@media only screen and (min-width: 1500px) {
	.section-div {
		width: 70%;
	}
	.profile-text-split {
		width: 60%;
	}
}

@media only screen and (min-width: 1800px) {
}

@media only screen and (min-width: 2100px) {
}
